<template>
  <v-dialog :value="value" width="1200px" persistent>
    <mf-loading-content v-if="loading" :loading="true"></mf-loading-content>
    <v-card v-else v-show="value" class="dialog-content">
      <!-- Header -->
      <v-card-title>
        <v-row align="center">
          <v-col>
            <span class="block-title">{{ titleDialog }}</span>
          </v-col>
          <v-col>
            <v-row justify="end">
              <v-col cols="auto">
                <v-icon @click="close">mdi-close-thick</v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-col cols="12" no-gutters class="pa-4">
          <v-row align="center">
            <v-col cols="4">
              <label class="detail-title">Nome</label>
              <mf-text-input v-model="form_theme.name" :rules="[stringRules, specialCharactersRules, charactersLimit30Rules]" outlined />
            </v-col>
            <v-col cols="auto">
              <mf-toggle v-model="form_theme.default" class="mx-2" label="Tema Padrão" color="#334A58" value />
            </v-col>
            <v-col cols="auto">
              <mf-toggle v-model="form_theme.active" class="mx-2" label="Disponível para customização" color="#334A58" value />
            </v-col>
          </v-row>
        </v-col>

        <v-col no-gutters class="pa-4">
          <label class="detail-title">Background</label>

          <v-row>
            <v-col cols="4">
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.primary !== form_theme.colors.primaryText || 'PRIMARY não pode ser igual a primaryText'
                ]"
                label="PRIMARY"
                :color.sync="form_theme.colors.primary"
                :contrast-text-color="form_theme.colors.primaryText"
              />
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.secondary !== form_theme.colors.secondaryText || 'SECONDARY não pode ser igual a secondaryText'
                ]"
                label="SECONDARY"
                :color.sync="form_theme.colors.secondary"
                :contrast-text-color="form_theme.colors.secondaryText"
              />
            </v-col>

            <v-col cols="4">
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.primaryLight !== form_theme.colors.primaryTextLight || 'primaryLight não pode ser igual a primaryTextLight'
                ]"
                label="PRIMARY_LIGHT"
                :color.sync="form_theme.colors.primaryLight"
                :contrast-text-color="form_theme.colors.primaryTextLight"
              />
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.secondaryLight !== form_theme.colors.secondaryTextLight || 'SECONDARY_LIGHT não pode ser igual a SECONDARY_TEXT_LIGHT'
                ]"
                label="SECONDARY_LIGHT"
                :color.sync="form_theme.colors.secondaryLight"
                :contrast-text-color="form_theme.colors.secondaryTextLight"
              />
            </v-col>

            <v-col cols="4">
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.primaryDark !== form_theme.colors.primaryTextDark || 'PRIMARY_DARK não pode ser igual a PRIMARY_TEXT_DARK'
                ]"
                label="PRIMARY_DARK"
                :color.sync="form_theme.colors.primaryDark"
                :contrast-text-color="form_theme.colors.primaryTextDark"
              />

              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.secondaryDark !== form_theme.colors.secondaryTextDark || 'secondaryDark não pode ser igual a secondaryTextDark'
                ]"
                label="secondaryDark"
                :color.sync="form_theme.colors.secondaryDark"
                :contrast-text-color="form_theme.colors.secondaryTextDark"
              />
            </v-col>
          </v-row>
        </v-col>

        <!-- Cores text -->
        <v-col class="pa-4">
          <v-card-title> Text </v-card-title>

          <v-row>
            <v-col cols="4">
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.primary !== form_theme.colors.primaryText || 'PRIMARY não pode ser igual a PRIMARY_TEXT'
                ]"
                label="PRIMARY_TEXT"
                :color.sync="form_theme.colors.primaryText"
              />
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.secondary !== form_theme.colors.secondaryText || 'SECONDARY não pode ser igual a SECONDARY_TEXT'
                ]"
                label="SECONDARY_TEXT"
                :color.sync="form_theme.colors.secondaryText"
              />
            </v-col>
            <v-col cols="4">
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.primaryLight !== form_theme.colors.primaryTextLight || 'PRIMARY_LIGHT não pode ser igual a PRIMARY_TEXT_LIGHT'
                ]"
                label="PRIMARY_TEXT_LIGHT"
                :color.sync="form_theme.colors.primaryTextLight"
              />
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.secondaryLight !== form_theme.colors.secondaryTextLight || 'SECONDARY_LIGHT não pode ser igual a SECONDARY_TEXT_LIGHT'
                ]"
                label="SECONDARY_TEXT_LIGHT"
                :color.sync="form_theme.colors.secondaryTextLight"
              />
            </v-col>
            <v-col cols="4">
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.primaryDark !== form_theme.colors.primaryTextDark || 'PRIMARY_DARK não pode ser igual a PRIMARY_TEXT_DARK'
                ]"
                label="PRIMARY_TEXT_DARK"
                :color.sync="form_theme.colors.primaryTextDark"
              />
              <color-picker
                edit
                :rules="[
                  stringRules,
                  charactersLimit9Rules,
                  form_theme.colors.secondaryDark !== form_theme.colors.secondaryTextDark || 'SECONDARY_DARK não pode ser igual a SECONDARY_TEXT_DARK'
                ]"
                label="SECONDARY_TEXT_DARK"
                :color.sync="form_theme.colors.secondaryTextDark"
              />
            </v-col>
          </v-row>
        </v-col>

        <!-- Advanced customSchema -->
        <v-row no-gutters class="pa-4">
          <v-col cols="12" class="pa-4">
            <label class="detail-title">Color Scheme(avançado):</label>
            <theme-editor-field :theme="form_theme.customSchema" />
          </v-col>
        </v-row>

        <v-row no-gutters class="px-4" justify="end">
          <v-col cols="auto" class="px-4">
            <mf-button label="Resetar Color Scheme para valores default" @click="resetCustomSchema"></mf-button>
          </v-col>
        </v-row>

        <v-row no-gutters justify="end" class="pa-4">
          <v-col cols="auto">
            <mf-action-buttons
              class="mr-1"
              :primary-button="{
                text: 'Salvar',
                action: save,
                isVisible: true,
                isDisabled: this.loadingButton || disable,
                isLoading: this.loadingButton
              }"
              :extra-button="{
                text: 'Cancelar',
                action: close,
                isVisible: true
              }"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { readableColor } from 'polished'
import { genHexLightDark } from '@/helpers/genHexLightDark'
import { debounceTime } from '@/helpers/debounceTime'
import { appDefault } from '@/modules/accounts/assets/app/index.js'
import { MUTATION_CREATE_MERCASHOP_THEME_COLORS, MUTATION_UPDATE_MERCASHOP_THEME_COLORS } from '@/modules/accounts/graphql'
export default {
  name: 'CreateOrUpdateThemeModal',
  components: {
    ColorPicker: () => import('@/components/colors/ColorPicker.vue'),
    ThemeEditorField: () => import('./ThemeEditorField.vue')
  },
  props: {
    value: Boolean,
    theme: {
      type: Object
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      panel: [],
      valid: true,
      disable: false,
      form_theme_default_values: {
        name: '',
        customSchema: null,
        colors: {
          primary: appDefault.colors.primary,
          primaryText: appDefault.colors.primary_text,
          primaryLight: appDefault.colors.primary_light,
          primaryTextLight: appDefault.colors.primary_text_light,
          primaryDark: appDefault.colors.primary_dark,
          primaryTextDark: appDefault.colors.primary_text_dark,
          secondary: appDefault.colors.secondary,
          secondaryText: appDefault.colors.secondary_text,
          secondaryLight: appDefault.colors.secondary_light,
          secondaryTextLight: appDefault.colors.secondary_text_light,
          secondaryDark: appDefault.colors.secondary_dark,
          secondaryTextDark: appDefault.colors.secondary_text_dark
        }
      },
      form_theme: {
        name: '',
        customSchema: null,
        default: false,
        active: true,
        colors: {
          primary: appDefault.colors.primary,
          primaryText: appDefault.colors.primary_text,
          primaryLight: appDefault.colors.primary_light,
          primaryTextLight: appDefault.colors.primary_text_light,
          primaryDark: appDefault.colors.primary_dark,
          primaryTextDark: appDefault.colors.primary_text_dark,
          secondary: appDefault.colors.secondary,
          secondaryText: appDefault.colors.secondary_text,
          secondaryLight: appDefault.colors.secondary_light,
          secondaryTextLight: appDefault.colors.secondary_text_light,
          secondaryDark: appDefault.colors.secondary_dark,
          secondaryTextDark: appDefault.colors.secondary_text_dark
        }
      },
      default_advanced_schema: null,
      loadingButton: false,
      specialCharactersRules: v => !/[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(v) || 'Campo não pode conter caractéres especiais',
      charactersLimit30Rules: v => (v || '').length <= 30 || 'Campo não pode ter mais que 30 caracteres',
      stringRules: v => !!v || 'Campo não pode ser nulo',
      charactersLimit9Rules: v => (v || '').length <= 9 || 'Campo não pode ter mais que 9 caracteres',
      titleDialog: '',
      loading: false
    }
  },
  computed: {
    colors() {
      return {
        primary: appDefault.colors.primary,
        primaryText: appDefault.colors.primary_text,
        primaryLight: appDefault.colors.primary_light,
        primaryTextLight: appDefault.colors.primary_text_light,
        primaryDark: appDefault.colors.primary_dark,
        primaryTextDark: appDefault.colors.primary_text_dark,
        secondary: appDefault.colors.secondary,
        secondaryText: appDefault.colors.secondary_text,
        secondaryLight: appDefault.colors.secondary_light,
        secondaryTextLight: appDefault.colors.secondary_text_light,
        secondaryDark: appDefault.colors.secondary_dark,
        secondaryTextDark: appDefault.colors.secondary_text_dark
      }
    }
  },
  watch: {
    'form_theme.colors.primary': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        const [baseLight, baseDark] = genHexLightDark(color)
        this.updateCustomSchemaByColorChange('primary', color, oldColor)
        this.updateCustomSchemaByColorChange('primaryLight', baseLight, this.form_theme.colors.primaryLight)
        this.updateCustomSchemaByColorChange('primaryDark', baseLight, this.form_theme.colors.primaryDark)
        this.updateCustomSchemaByColorChange('primaryText', this.customReadableColor(color), this.customReadableColor(oldColor))
        this.updateCustomSchemaByColorChange('primaryTextLight', this.customReadableColor(baseLight), this.form_theme.colors.primaryTextLight)
        this.updateCustomSchemaByColorChange('primaryTextDark', this.customReadableColor(baseDark), this.form_theme.colors.primaryTextDark)
        this.form_theme.colors = {
          ...this.form_theme.colors,
          primaryLight: baseLight,
          primaryDark: baseDark,
          primaryText: this.customReadableColor(color),
          primaryTextLight: this.customReadableColor(baseLight),
          primaryTextDark: this.customReadableColor(baseDark)
        }
      })
    },
    'form_theme.colors.primaryLight': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.updateCustomSchemaByColorChange('primaryLight', color, oldColor)
        this.updateCustomSchemaByColorChange('primaryTextLight', this.customReadableColor(color), this.customReadableColor(oldColor))
        this.form_theme.colors.primaryTextLight = this.customReadableColor(color)
      })
    },
    'form_theme.colors.primaryDark': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.updateCustomSchemaByColorChange('primaryDark', color, oldColor)
        this.updateCustomSchemaByColorChange('primaryTextDark', this.customReadableColor(color), this.customReadableColor(oldColor))
        this.form_theme.colors.primaryTextDark = this.customReadableColor(color)
      })
    },
    'form_theme.colors.secondary': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        const [baseLight, baseDark] = genHexLightDark(color)
        this.updateCustomSchemaByColorChange('secondary', color, oldColor)
        this.updateCustomSchemaByColorChange('secondaryLight', baseLight, this.form_theme.colors.secondaryLight)
        this.updateCustomSchemaByColorChange('secondaryDark', baseLight, this.form_theme.colors.secondaryDark)
        this.updateCustomSchemaByColorChange('secondaryText', this.customReadableColor(color), this.customReadableColor(oldColor))
        this.updateCustomSchemaByColorChange('secondaryTextLight', this.customReadableColor(baseLight), this.form_theme.colors.secondaryTextLight)
        this.updateCustomSchemaByColorChange('secondaryTextDark', this.customReadableColor(baseDark), this.form_theme.colors.secondaryTextDark)
        this.form_theme.colors = {
          ...this.form_theme.colors,
          secondaryLight: baseLight,
          secondaryDark: baseDark,
          secondaryText: this.customReadableColor(color),
          secondaryTextLight: this.customReadableColor(baseLight),
          secondaryTextDark: this.customReadableColor(baseDark)
        }
      })
    },
    'form_theme.colors.secondaryLight': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.updateCustomSchemaByColorChange('secondaryLight', color, oldColor)
        this.updateCustomSchemaByColorChange('secondaryTextLight', this.customReadableColor(color), this.customReadableColor(oldColor))
        this.form_theme.colors.secondaryTextLight = this.customReadableColor(color)
      })
    },
    'form_theme.colors.secondaryDark': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.updateCustomSchemaByColorChange('secondaryDark', color, oldColor)
        this.updateCustomSchemaByColorChange('secondaryTextDark', this.customReadableColor(color), this.customReadableColor(oldColor))
        this.form_theme.colors.secondaryTextDark = this.customReadableColor(color)
      })
    }
  },
  created() {
    if (!this.isCreate) {
      delete this.theme?.__typename
      delete this.theme.customSchema?.__typename
      delete this.theme.colors?.__typename
    }
    this.setValues(this.theme)
  },
  methods: {
    updateCustomSchemaByColorChange(key, newColor, oldColor) {
      const mergeDefaultSchema = (defaultObj, customObj) => {
        for (const [defaultKey, defaultValue] of Object.entries(defaultObj)) {
          if (typeof defaultValue === 'object') {
            mergeDefaultSchema(defaultValue, customObj[defaultKey] || {})
          } else if (defaultValue === key) {
            if (customObj[defaultKey] === oldColor) {
              this.$set(customObj, defaultKey, newColor)
            }
          }
        }
      }
      mergeDefaultSchema(this.default_advanced_schema, this.form_theme.customSchema)
    },
    resetCustomSchema() {
      const colors = this.form_theme.colors
      const resolveColors = value => {
        if (colors[value]) {
          return colors[value]
        }
        return value
      }
      const resetSchema = defaultObj => {
        const newSchema = {}
        for (const [key, value] of Object.entries(defaultObj)) {
          if (typeof value === 'object' && value !== null) {
            newSchema[key] = resetSchema(value)
          } else {
            newSchema[key] = resolveColors(value)
          }
        }
        return newSchema
      }
      this.form_theme.customSchema = resetSchema(this.default_advanced_schema)
    },
    customReadableColor(color) {
      return readableColor(color, '#000000', '#FFFFFF')
    },
    onAfterColorChange(color, oldColor, cb) {
      if (color && oldColor && color !== oldColor) {
        debounceTime(() => {
          return cb()
        }, 400)
      }
    },
    async setValues(_theme) {
      const theme = this.isCreate ? this.form_theme : _theme
      this.titleDialog = _theme?.name ? `Editando o tema - ${_theme?.name}` : 'Criando um tema'
      if (!theme?.colors) {
        theme.colors = appDefault.colors
      }
      try {
        this.loading = true
        const response = await fetch('https://multilojas.vercel.app/api/default-theme')
        const defaultAdvancedSchema = await response.json()
        this.default_advanced_schema = defaultAdvancedSchema
        const mergedDefaultWithPreviousAdvancedTheme = this.mergeThemes(defaultAdvancedSchema, theme?.colors || {}, theme?.customSchema || {})
        this.form_theme = { ...theme, customSchema: { ...mergedDefaultWithPreviousAdvancedTheme } }
      } catch (error) {
        this.disable = true
        this.$alert({
          alert_message: `Erro ao buscar os temas padrões`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.loading = false
      }
    },
    mergeThemes(defaultTheme, testeColors, previousTheme) {
      const replaceColors = (obj, colorMap, prevTheme) => {
        if (Array.isArray(obj)) {
          return obj.map(item => replaceColors(item, colorMap, prevTheme))
        }
        if (typeof obj === 'object' && obj !== null) {
          const newObj = {}
          for (const key in obj) {
            const prevValue = prevTheme && prevTheme[key]
            if (typeof obj[key] === 'object' && obj[key] !== null) {
              newObj[key] = replaceColors(obj[key], colorMap, prevValue)
            } else if (prevValue !== undefined) {
              newObj[key] = prevValue
            } else if (typeof obj[key] === 'string' && colorMap[obj[key]]) {
              newObj[key] = colorMap[obj[key]]
            } else {
              newObj[key] = obj[key]
            }
          }
          return newObj
        }
        return obj
      }
      return replaceColors(defaultTheme, testeColors, previousTheme)
    },
    async create() {
      await this.$apollo.mutate({
        mutation: MUTATION_CREATE_MERCASHOP_THEME_COLORS,
        context: {
          headers: {
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        },
        variables: {
          mercashopTheme: {
            customSchema: this.form_theme.customSchema,
            name: this.form_theme.name,
            colors: { ...this.form_theme.colors },
            active: this.form_theme.active,
            default: this.form_theme.default
          }
        }
      })
    },
    async update() {
      await this.$apollo.mutate({
        mutation: MUTATION_UPDATE_MERCASHOP_THEME_COLORS,
        context: {
          headers: {
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        },
        variables: {
          mercashopThemeId: this.theme._id,
          update: {
            customSchema: this.form_theme.customSchema,
            name: this.form_theme.name,
            colors: { ...this.form_theme.colors },
            active: this.form_theme.active,
            default: this.form_theme.default
          }
        }
      })
    },
    async save() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      try {
        this.loadingButton = true
        if (this.isCreate) {
          await this.create()
        } else {
          await this.update()
        }
        this.$alert({
          alert_message: `${this.isCreate ? 'Criação' : 'Edição'} realizada com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.close(true)
      } catch (error) {
        console.log()
        console.log(error)
        const message =
          error.message === 'GraphQL error: MercashopTheme cannot be default and inactive'
            ? 'Não é possivel inativar um tema padrão'
            : `Erro na ${this.isCreate ? 'Criação' : 'Edição'}`
        this.$alert({
          alert_message: message,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.loadingButton = false
      }
    },
    close(refresh = false) {
      this.$emit('input', false)
      this.$emit('close', refresh)
    }
  }
}
</script>
